import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import User from './user';
import { authApi, RegisterRequest } from './authApi';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export type RegisterOutcomeEnum =
  'SUCCESS'
  | 'EMAIL_ALREADY_USED'
  | 'FORM_ERROR'
  | 'EMAIL_ERROR';

export interface RegisterOutcome {
  outcome: RegisterOutcomeEnum;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string, managerAccess?: boolean) => Promise<void>;
  logout: () => Promise<void>;
  register: (request: RegisterRequest) => Promise<RegisterOutcome>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<string, (state: State, action: any) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve({outcome: 'SUCCESS'}) // FIXME why?
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getAccessToken = (): string | null => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    return params.get('accessToken');
  };

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const queryAccessToken = getAccessToken();

        if (queryAccessToken) {
          window.localStorage.setItem('accessToken', queryAccessToken);

          // clean up url
          const newUrl = window.location.href.split('?')[0];
          window.history.pushState({}, '', newUrl);
        }

        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
          authApi.me().then((user) => {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user
              }
            });
          }).catch(() => {
            window.localStorage.removeItem('accessToken');
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          })
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    localStorage.removeItem('accessToken');
    return authApi.login({ email, password })
      .then((response) => {
        localStorage.setItem('accessToken', response.accessToken);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: response.user
          }
        });
      });
  };

  const logout = async (): Promise<void> => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (request: RegisterRequest): Promise<RegisterOutcome> => {
    return authApi.register(request)
      .then((response) => {
        // FIXME login(request.affiliate.email, request.affiliate.password);
        return response;
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


export default AuthContext;
