import { Card, CardContent, Grid, Typography } from "@mui/material";
import useAuth from "../../auth/useAuth";

export const Dashboard = ({ stats }) => {
  const auth = useAuth();
  function createCard(title, value, isCurrency) {
    return (
      <Grid item xs>
        <Card>
          <CardContent>
            <Typography sx={{ fontSize: 18, whiteSpace: "nowrap" }} color="text.secondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5" sx={{ whiteSpace: "nowrap" }} component="div">
              {!isNaN(value) && (
                <>
                  {isCurrency && (
                    <>
                      {value < 0 ? "-" : ""}
                      £
                      {(Math.abs(value)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </>
                  )}
                  {!isCurrency && `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </>
              )}
              {isNaN(value) && "\u00A0"}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <div className="dashboard">
      <Grid container spacing={1}>
        {createCard("Clicks", stats?.clicks, false)}
        {createCard("Registrations", stats?.registrations, false)}
        {createCard("FTDs", stats?.ftds, false)}
        {auth.user.authorities.includes('VIEW_NET_CASH') ?? createCard("Net Cash", stats?.netCash, true)}
        {createCard("Rev Share", stats?.revShareCommission, true)}
        {createCard("CPA", stats?.cpaCommission, true)}
        {createCard("Total Commision", stats?.revShareCommission + stats?.cpaCommission, true)}
      </Grid>
    </div>
  );
};
