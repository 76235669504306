import { useEffect, useState } from "react";
import { Dashboard } from "../../components/Dashboard/Dashboard";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import { applyCurrencyFormatters } from "../../common/helper";
import "./BrandsReport.css";
import fetchData from "../../api/data";
import { toastApiError } from "../../utils/toastApiError";
import useAuth from "../../auth/useAuth";

const customStyles = {
  header: {
    style: {
      fontSize: "24px",
      fontWeight: "800",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
    },
  },
};

const perPageList = [10, 50, 100, 250];

const BrandsReport = ({ datesFilter, brandsFilter }) => {
  const auth = useAuth();
  const [casinos, setCasinos] = useState(null);
  const [deals, setDeals] = useState(null);

  const [dealsPagination, setDealsPagination] = useState({
    page: 1,
    perPage: perPageList[0],
  });
  const [casinosPagination, setCasinosPagination] = useState({
    page: 1,
    perPage: perPageList[0],
  });

  useEffect(() => {
    return fetchData({
      url: `/api/v3/reports/casinos?${datesFilter}&${brandsFilter}`,
      onSuccess: ({ data: { reports, totalsReport } }) => {
        setCasinos({
          reports: reports,
          totals: totalsReport,
          error: false,
        });
      },
      onError: (e) => {
        toastApiError(e);
        setCasinos({error: true});
      },
    });
  }, [datesFilter, brandsFilter]);

  useEffect(() => {
    return fetchData({
      url: `/api/v3/reports/deals?${datesFilter}&${brandsFilter}`,
      onSuccess: ({ data: { reports } }) => {
        setDeals({
          reports: reports,
          error: false,
        });
      },
      onError: (e) => {
        toastApiError(e);
        setDeals({error: true});
      },
    });
  }, [datesFilter, brandsFilter]);

  const dataCasino = [
    {
      name: "Brand",
      selector: (row) => row.brandName,
      sortable: true,
      grow: 3,
      style: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    {
      name: "Clicks",
      selector: (row) => row.clicks,
      sortable: true,
      right: true,
    },
    {
      name: "Registrations",
      selector: (row) => row.registrations,
      sortable: true,
      right: true,
    },
    {
      name: "FTDs",
      selector: (row) => row.ftds,
      sortable: true,
      right: true,
    },
    {
      name: "No of deposits",
      selector: (row) => row.numberOfDeposits,
      sortable: true,
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Deposits",
      selector: (row) => row.deposits,
      sortable: true,
      right: true,
      format: "currency",
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Net Cash",
      selector: (row) => row.netCash,
      sortable: true,
      right: true,
      format: "currency",
      omit: !auth.user.authorities.includes('VIEW_NET_CASH')
    },
    {
      name: "Rev Share Commission",
      selector: (row) => row.revShareCommission,
      sortable: true,
      right: true,
      format: "currency",
    },
    {
      name: "CPA Commission",
      selector: (row) => row.cpaCommission,
      sortable: true,
      right: true,
      format: "currency",
    },
  ];

  const dataDeal = [
    {
      name: "Deal",
      selector: (row) => row.dealName,
      sortable: true,
      grow: 3,
      style: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    {
      name: "Clicks",
      selector: (row) => row.clicks,
      sortable: true,
      right: true,
    },
    {
      name: "Registrations",
      selector: (row) => row.registrations,
      sortable: true,
      right: true,
    },
    {
      name: "FTDs",
      selector: (row) => row.ftds,
      sortable: true,
      right: true,
    },
    {
      name: "No of deposits",
      selector: (row) => row.numberOfDeposits,
      sortable: true,
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Deposits",
      selector: (row) => row.deposits,
      sortable: true,
      format: "currency",
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Net Cash",
      selector: (row) => row.netCash,
      sortable: true,
      format: "currency",
      right: true,
      omit: !auth.user.authorities.includes('VIEW_NET_CASH')
    },
    {
      name: "Rev Share Commission",
      selector: (row) => row.revShareCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: "CPA Commission",
      selector: (row) => row.cpaCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
  ];

  const handlePageCasinoChange = (page) => setCasinosPagination({ ...casinosPagination, page: page });
  const handlePageDealChange = (page) => setDealsPagination({ ...dealsPagination, page: page });
  const handlePerPageCasinoChange = (perPage) => setCasinosPagination({ ...casinosPagination, perPage: perPage });
  const handlePerPageDealChange = (perPage) => setDealsPagination({ ...dealsPagination, perPage: perPage });

  if (!casinos || !deals) {
    return <>Loading...</>;
  }

  return (
    <Row>
      <Dashboard stats={casinos?.totals} />
      <div className="data__wrapper">
        {casinos?.error && "Error loading casinos"}
        {!casinos?.error && (
          <DataTable
            title="Casinos"
            columns={applyCurrencyFormatters(dataCasino)}
            data={casinos?.reports ?? []}
            progressPending={!casinos}
            customStyles={customStyles}
            pagination
            onChangePage={handlePageCasinoChange}
            progressComponent={<Circular />}
            paginationPerPage={casinosPagination.perPage}
            paginationRowsPerPageOptions={perPageList}
            onChangeRowsPerPage={handlePerPageCasinoChange}
          />
        )}
      </div>
      <div className="data__wrapper">
        {deals?.error && "Error loading deals"}
        {!deals?.error && (
          <DataTable
            title="Deals"
            columns={applyCurrencyFormatters(dataDeal)}
            data={deals?.reports ?? []}
            progressPending={!deals}
            customStyles={customStyles}
            pagination
            onChangePage={handlePageDealChange}
            progressComponent={<Circular />}
            paginationPerPage={dealsPagination.perPage}
            paginationRowsPerPageOptions={perPageList}
            onChangeRowsPerPage={handlePerPageDealChange}
          />
        )}
      </div>
    </Row>
  );
};

export default connectTopLine(true, true, BrandsReport);
